import { Store } from 'store';

interface LayoutContainerStore {
  size: 'default' | 'fullscreen';
}

class LayoutContainer extends Store<LayoutContainerStore> {
  constructor() {
    super({ name: 'layout-container', initialState: { size: 'default' } });
  }

  setSize(size: LayoutContainerStore['size']): void {
    this.set('size', size);
  }
}

export type { LayoutContainerStore };
export default new LayoutContainer();
