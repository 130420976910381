import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import(/* webpackPreload: true */ '../landing'));

const routes: Route = {
  name: id,
  path: '/{lang?}',
  element: <AsyncPage />,
  searchParams: ['forceShow'],
  meta: {
    layout: false,
  },
};

export default routes;
